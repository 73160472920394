@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600&display=swap');

*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
	all: unset;
	display: revert;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

ol,
ul {
	list-style: none;
}

img {
	max-width: 100%;
}

table {
	border-collapse: collapse;
}

textarea {
	white-space: revert;
}

html,
body {
	/* Cool, random color palettes */
	--color-1: #96E6B3ff;
	--color-2: #F46036ff;
	--color-3: #ff6663ff;
	--color-4: #e0ff4fff;
	--color-5: #fefffeff;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
	background-color: transparent;
	background-image: none;
	color: #eee;
}

html {
	background-color: #111;
	background-image: url("https://rayganancial.com/images/bg.jpg");
	background-size: auto 600px;
	background-repeat: no-repeat;
	background-position: right top;
}

/* Header */

#header {
	width: 100%;
	z-index: 1000;
	background-color: rgb(13, 13, 13);;
	border-bottom: 1px solid #191919;
	display: flex;
	height: 3.5em;
	justify-content: space-between;
	position: fixed;
	left: 0;
	top: 0;
	line-height: 3.5em;
}
#header > h1 {
	padding: 0 0 0 1.5rem;
	white-space: nowrap;
	font-weight: 800;
	margin: 0 0 1em;
	text-transform: lowercase;
	letter-spacing: .2em;
}
#header > h1 > a {
	cursor: pointer;
}
#nav {
	border-left: 1px solid #191919;
	flex: 1 1;
	margin-left: 1.5em;
	padding-left: 1.5em;
	text-transform: lowercase;
}
#nav > ul > li {
	display: inline-block;
}
#nav > ul > li {
	font-size: .8em;
	letter-spacing: .2em;
	padding-left: 1.5em;
}
#nav > ul > li:first-child {
	padding-left: 0;
}
#nav > ul > li > a {
	cursor: pointer;
	padding-right: 1.5em;
	border-right: 1px solid #191919;
}
#nav > ul > li:last-child > a {
	border-right: none;
}

/* End Header */

#logo {
	float: right;
	background: #fff;
	color: #111;
	width: 100px;
	height: 100px;
	border-top-right-radius: 20px;
	margin-right: 5em;
	margin-top: 40px;
	margin-left: 2em;
}

#logo>.logo-inner {
	padding-top: 10%;
	padding-left: 30%;
	text-align: center;
}

#logo>.logo-inner>span {
	font-size: 2em;
	font-weight: 1000;
}

.rg-app {
	padding-top: 100px;
	padding-bottom: 100px;
}

.color-1 {
	background-color: var(--color-1) !important;
}
.color-2 {
	background-color: var(--color-2) !important;
}
.color-3 {
	background-color: var(--color-3) !important;
}
.color-4 {
	background-color: var(--color-4) !important;
}
.color-5 {
	background-color: var(--color-5) !important;
}

/* Home */

.home {
	padding: 0 5em 2em;
	text-transform: lowercase;
}

.home h2 {
	font-size: 5em;
}

.home .intro {
	font-size: 2em;
}

.home .actions {
	margin-top: 1.5em;
}

/* End Home */

#title {
	font-size: 1.75em;
}
#title > .title-intro {
	font-weight: normal;
	font-size: .75em;
}

.rg-app h2.title {
	font-size: 2.5em;
	text-transform: lowercase;
}

.descriptors {
	color: #888888;
	letter-spacing: 0.15em;
	text-transform: lowercase;
}

.home .descriptors {
	margin-top: 1.5em;
	line-height: 2em;
	border-top: 1px solid #2b2b2b;
	padding-top: 1.5em;
}

.inner .descriptors {
	margin-right: 2em;
	margin-bottom: 2.5em;
	letter-spacing: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}
strong {
    font-weight: 500;
}

.loading-container {
    display: flex;
    flex-direction: column;
}
.loading {
    height: 20px;
    margin-bottom: 5px;
	background: #222;
	background-image: linear-gradient(
		to right,
		#222 0%,
		#333 20%,
		#222 40%,
		#333 100%
	);
	background-repeat: no-repeat;
	background-size: 800px 104px;
	display: inline-block;
	position: relative;

	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-name: placeholderShimmer;
	-webkit-animation-timing-function: linear;

	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeholderShimmer;
	animation-timing-function: linear;
}
.loading.quarter {
    width: 25%;
}
.loading.half {
    width: 50%;
}
.loading.short {
	height: 15px;
}
.loading.full {
    width: 100%;
}
.loading.end {
    width: 80%;
}

@-webkit-keyframes placeholderShimmer {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

@keyframes placeholderShimmer {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

.inner {
	margin-top: 20px;
	padding-left: 5em;
	padding-right: 5em;
}

.resume h2 {
	font-size: 1.25em;
}

.resume-section {
	display: flex;
	flex-direction: row;
}

.resume .skill {
	display: inline-block;
	margin-right: .5em;
	margin-bottom: .5em;
	padding: .2em 1em;
	border: 1px solid #555;
	text-transform: lowercase;
}

#details-container {
	min-height: 95px;
}
#work-container {
	min-height: 385px;
}
#edu-container {
	min-height: 125px;
}

.detail .email {
	display: block;
	cursor: pointer;
}

.resume-section-title,
.resume-section-content {
    padding: 20px 0 10px;
}

.resume-section-title {
	width: 100%;
    max-width: 140px;
    min-width: 140px;
	text-align: right;
	text-transform: lowercase;
	padding-right: 20px;
	padding-left: 20px;
	border-right: 1px solid #555;
	font-weight: 600;
}

.resume-section-content {
    padding-left: 20px;
	min-width: 250px;
	overflow: hidden;
	padding-right: 2em;
}

#skills-container .resume-section-content {
	max-width: 25em;
}

.resume-section-content .work, .resume-section-content .edu {
    margin-bottom: 20px;
}

.work > em, .edu > em {
	display: block;
	font-style: italic;
	font-size: .8em;
	font-weight: 500;
}

@media (min-width: 768px) and (max-width: 820px) {
	
}

@media (max-width: 767px) {

	html,
	body {
		font-size: .9em;
	}
	
	.rg-app {
		padding-top: 2em;
		padding-left: 2em;
	}

	.home {
		padding-left: 2em;
		padding-right: 4em;
	}

	.inner {
		padding-left: 2em;
		padding-right: 4em;
	}

	#logo {
		float: right;
		width: 50px;
		height: 50px;
		font-size: 0.7em;
		border-top-right-radius: 10px;
		margin: 8em 5em 2em 100%;
	}

	.resume-section-title {
		max-width: 80px;
		min-width: 80px;
		padding-left: 0;
	}

	.resume-section-content {
		min-width: inherit;
		padding-right: 2em;
	}

	#details-container {
		min-height: 85px;
	}
	
	#work-container {
		min-height: 330px;
	}

	#edu-container {
		min-height: 105px;
	}

	.loading {
		height: 15px
	}
	.loading.quarter {
		width: 10%;
	}
	.loading.half {
		width: 30%;
	}
	.loading.short {
		height: 10px;
	}
	.loading.full {
		width: 80%;
	}
	.loading.end {
		width: 60%;
	}
}

.rg-pokemon {
	margin-top: 8em;
}
.rg-pokemon h3 {
	margin-bottom: .5em;
}