@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
	all: unset;
	display: revert;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

ol,
ul {
	list-style: none;
}

img {
	max-width: 100%;
}

table {
	border-collapse: collapse;
}

textarea {
	white-space: revert;
}

html,
body {
	/* Cool, random color palettes */
	--color-1: #96E6B3ff;
	--color-2: #F46036ff;
	--color-3: #ff6663ff;
	--color-4: #e0ff4fff;
	--color-5: #fefffeff;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
	background-color: transparent;
	background-image: none;
	color: #eee;
}

html {
	background-color: #111;
	background-image: url("https://rayganancial.com/images/bg.jpg");
	background-size: auto 600px;
	background-repeat: no-repeat;
	background-position: right top;
}

/* Header */

#header {
	width: 100%;
	z-index: 1000;
	background-color: rgb(13, 13, 13);;
	border-bottom: 1px solid #191919;
	display: flex;
	height: 3.5em;
	justify-content: space-between;
	position: fixed;
	left: 0;
	top: 0;
	line-height: 3.5em;
}
#header > h1 {
	padding: 0 0 0 1.5rem;
	white-space: nowrap;
	font-weight: 800;
	margin: 0 0 1em;
	text-transform: lowercase;
	letter-spacing: .2em;
}
#header > h1 > a {
	cursor: pointer;
}
#nav {
	border-left: 1px solid #191919;
	flex: 1 1;
	margin-left: 1.5em;
	padding-left: 1.5em;
	text-transform: lowercase;
}
#nav > ul > li {
	display: inline-block;
}
#nav > ul > li {
	font-size: .8em;
	letter-spacing: .2em;
	padding-left: 1.5em;
}
#nav > ul > li:first-child {
	padding-left: 0;
}
#nav > ul > li > a {
	cursor: pointer;
	padding-right: 1.5em;
	border-right: 1px solid #191919;
}
#nav > ul > li:last-child > a {
	border-right: none;
}

/* End Header */

#logo {
	float: right;
	background: #fff;
	color: #111;
	width: 100px;
	height: 100px;
	border-top-right-radius: 20px;
	margin-right: 5em;
	margin-top: 40px;
	margin-left: 2em;
}

#logo>.logo-inner {
	padding-top: 10%;
	padding-left: 30%;
	text-align: center;
}

#logo>.logo-inner>span {
	font-size: 2em;
	font-weight: 1000;
}

.rg-app {
	padding-top: 100px;
	padding-bottom: 100px;
}

.color-1 {
	background-color: var(--color-1) !important;
}
.color-2 {
	background-color: var(--color-2) !important;
}
.color-3 {
	background-color: var(--color-3) !important;
}
.color-4 {
	background-color: var(--color-4) !important;
}
.color-5 {
	background-color: var(--color-5) !important;
}

/* Home */

.home {
	padding: 0 5em 2em;
	text-transform: lowercase;
}

.home h2 {
	font-size: 5em;
}

.home .intro {
	font-size: 2em;
}

.home .actions {
	margin-top: 1.5em;
}

/* End Home */

#title {
	font-size: 1.75em;
}
#title > .title-intro {
	font-weight: normal;
	font-size: .75em;
}

.rg-app h2.title {
	font-size: 2.5em;
	text-transform: lowercase;
}

.descriptors {
	color: #888888;
	letter-spacing: 0.15em;
	text-transform: lowercase;
}

.home .descriptors {
	margin-top: 1.5em;
	line-height: 2em;
	border-top: 1px solid #2b2b2b;
	padding-top: 1.5em;
}

.inner .descriptors {
	margin-right: 2em;
	margin-bottom: 2.5em;
	letter-spacing: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}
strong {
    font-weight: 500;
}

.loading-container {
    display: flex;
    flex-direction: column;
}
.loading {
    height: 20px;
    margin-bottom: 5px;
	background: #222;
	background-image: linear-gradient(
		to right,
		#222 0%,
		#333 20%,
		#222 40%,
		#333 100%
	);
	background-repeat: no-repeat;
	background-size: 800px 104px;
	display: inline-block;
	position: relative;

	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-name: placeholderShimmer;
	-webkit-animation-timing-function: linear;

	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeholderShimmer;
	animation-timing-function: linear;
}
.loading.quarter {
    width: 25%;
}
.loading.half {
    width: 50%;
}
.loading.short {
	height: 15px;
}
.loading.full {
    width: 100%;
}
.loading.end {
    width: 80%;
}

@-webkit-keyframes placeholderShimmer {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

@keyframes placeholderShimmer {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

.inner {
	margin-top: 20px;
	padding-left: 5em;
	padding-right: 5em;
}

.resume h2 {
	font-size: 1.25em;
}

.resume-section {
	display: flex;
	flex-direction: row;
}

.resume .skill {
	display: inline-block;
	margin-right: .5em;
	margin-bottom: .5em;
	padding: .2em 1em;
	border: 1px solid #555;
	text-transform: lowercase;
}

#details-container {
	min-height: 95px;
}
#work-container {
	min-height: 385px;
}
#edu-container {
	min-height: 125px;
}

.detail .email {
	display: block;
	cursor: pointer;
}

.resume-section-title,
.resume-section-content {
    padding: 20px 0 10px;
}

.resume-section-title {
	width: 100%;
    max-width: 140px;
    min-width: 140px;
	text-align: right;
	text-transform: lowercase;
	padding-right: 20px;
	padding-left: 20px;
	border-right: 1px solid #555;
	font-weight: 600;
}

.resume-section-content {
    padding-left: 20px;
	min-width: 250px;
	overflow: hidden;
	padding-right: 2em;
}

#skills-container .resume-section-content {
	max-width: 25em;
}

.resume-section-content .work, .resume-section-content .edu {
    margin-bottom: 20px;
}

.work > em, .edu > em {
	display: block;
	font-style: italic;
	font-size: .8em;
	font-weight: 500;
}

@media (min-width: 768px) and (max-width: 820px) {
	
}

@media (max-width: 767px) {

	html,
	body {
		font-size: .9em;
	}
	
	.rg-app {
		padding-top: 2em;
		padding-left: 2em;
	}

	.home {
		padding-left: 2em;
		padding-right: 4em;
	}

	.inner {
		padding-left: 2em;
		padding-right: 4em;
	}

	#logo {
		float: right;
		width: 50px;
		height: 50px;
		font-size: 0.7em;
		border-top-right-radius: 10px;
		margin: 8em 5em 2em 100%;
	}

	.resume-section-title {
		max-width: 80px;
		min-width: 80px;
		padding-left: 0;
	}

	.resume-section-content {
		min-width: inherit;
		padding-right: 2em;
	}

	#details-container {
		min-height: 85px;
	}
	
	#work-container {
		min-height: 330px;
	}

	#edu-container {
		min-height: 105px;
	}

	.loading {
		height: 15px
	}
	.loading.quarter {
		width: 10%;
	}
	.loading.half {
		width: 30%;
	}
	.loading.short {
		height: 10px;
	}
	.loading.full {
		width: 80%;
	}
	.loading.end {
		width: 60%;
	}
}

.rg-pokemon {
	margin-top: 8em;
}
.rg-pokemon h3 {
	margin-bottom: .5em;
}
/*
==============================================
CSS3 ANIMATION CHEAT SHEET
==============================================

Made by Justin Aguilar

www.justinaguilar.com/animations/

Questions, comments, concerns, love letters:
justin@justinaguilar.com
==============================================
*/

/*
==============================================
slideDown
==============================================
*/


.slideDown{
	animation-name: slideDown;
	-webkit-animation-name: slideDown;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	

	visibility: visible !important;						
}

@keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
		        transform: translateY(-100%);
	}
	50%{
		-webkit-transform: translateY(8%);
		        transform: translateY(8%);
	}
	65%{
		-webkit-transform: translateY(-4%);
		        transform: translateY(-4%);
	}
	80%{
		-webkit-transform: translateY(4%);
		        transform: translateY(4%);
	}
	95%{
		-webkit-transform: translateY(-2%);
		        transform: translateY(-2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}		
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
	}
	50%{
		-webkit-transform: translateY(8%);
	}
	65%{
		-webkit-transform: translateY(-4%);
	}
	80%{
		-webkit-transform: translateY(4%);
	}
	95%{
		-webkit-transform: translateY(-2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
	}	
}

/*
==============================================
slideUp
==============================================
*/


.slideUp{
	animation-name: slideUp;
	-webkit-animation-name: slideUp;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;

	visibility: visible !important;			
}

@keyframes slideUp {
	0% {
		-webkit-transform: translateY(100%);
		        transform: translateY(100%);
	}
	50%{
		-webkit-transform: translateY(-8%);
		        transform: translateY(-8%);
	}
	65%{
		-webkit-transform: translateY(4%);
		        transform: translateY(4%);
	}
	80%{
		-webkit-transform: translateY(-4%);
		        transform: translateY(-4%);
	}
	95%{
		-webkit-transform: translateY(2%);
		        transform: translateY(2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}	
}

@-webkit-keyframes slideUp {
	0% {
		-webkit-transform: translateY(100%);
	}
	50%{
		-webkit-transform: translateY(-8%);
	}
	65%{
		-webkit-transform: translateY(4%);
	}
	80%{
		-webkit-transform: translateY(-4%);
	}
	95%{
		-webkit-transform: translateY(2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
	}	
}

/*
==============================================
slideLeft
==============================================
*/


.slideLeft{
	animation-name: slideLeft;
	-webkit-animation-name: slideLeft;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes slideLeft {
	0% {
		-webkit-transform: translateX(150%);
		        transform: translateX(150%);
	}
	50%{
		-webkit-transform: translateX(-8%);
		        transform: translateX(-8%);
	}
	65%{
		-webkit-transform: translateX(4%);
		        transform: translateX(4%);
	}
	80%{
		-webkit-transform: translateX(-4%);
		        transform: translateX(-4%);
	}
	95%{
		-webkit-transform: translateX(2%);
		        transform: translateX(2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
	}
}

@-webkit-keyframes slideLeft {
	0% {
		-webkit-transform: translateX(150%);
	}
	50%{
		-webkit-transform: translateX(-8%);
	}
	65%{
		-webkit-transform: translateX(4%);
	}
	80%{
		-webkit-transform: translateX(-4%);
	}
	95%{
		-webkit-transform: translateX(2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
	}
}

/*
==============================================
slideRight
==============================================
*/


.slideRight{
	animation-name: slideRight;
	-webkit-animation-name: slideRight;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes slideRight {
	0% {
		-webkit-transform: translateX(-150%);
		        transform: translateX(-150%);
	}
	50%{
		-webkit-transform: translateX(8%);
		        transform: translateX(8%);
	}
	65%{
		-webkit-transform: translateX(-4%);
		        transform: translateX(-4%);
	}
	80%{
		-webkit-transform: translateX(4%);
		        transform: translateX(4%);
	}
	95%{
		-webkit-transform: translateX(-2%);
		        transform: translateX(-2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
	}	
}

@-webkit-keyframes slideRight {
	0% {
		-webkit-transform: translateX(-150%);
	}
	50%{
		-webkit-transform: translateX(8%);
	}
	65%{
		-webkit-transform: translateX(-4%);
	}
	80%{
		-webkit-transform: translateX(4%);
	}
	95%{
		-webkit-transform: translateX(-2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
	}
}

/*
==============================================
slideExpandUp
==============================================
*/


.slideExpandUp{
	animation-name: slideExpandUp;
	-webkit-animation-name: slideExpandUp;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease -out;

	visibility: visible !important;	
}

@keyframes slideExpandUp {
	0% {
		-webkit-transform: translateY(100%) scaleX(0.5);
		        transform: translateY(100%) scaleX(0.5);
	}
	30%{
		-webkit-transform: translateY(-8%) scaleX(0.5);
		        transform: translateY(-8%) scaleX(0.5);
	}	
	40%{
		-webkit-transform: translateY(2%) scaleX(0.5);
		        transform: translateY(2%) scaleX(0.5);
	}
	50%{
		-webkit-transform: translateY(0%) scaleX(1.1);
		        transform: translateY(0%) scaleX(1.1);
	}
	60%{
		-webkit-transform: translateY(0%) scaleX(0.9);
		        transform: translateY(0%) scaleX(0.9);		
	}
	70% {
		-webkit-transform: translateY(0%) scaleX(1.05);
		        transform: translateY(0%) scaleX(1.05);
	}			
	80%{
		-webkit-transform: translateY(0%) scaleX(0.95);
		        transform: translateY(0%) scaleX(0.95);		
	}
	90% {
		-webkit-transform: translateY(0%) scaleX(1.02);
		        transform: translateY(0%) scaleX(1.02);
	}	
	100%{
		-webkit-transform: translateY(0%) scaleX(1);
		        transform: translateY(0%) scaleX(1);		
	}
}

@-webkit-keyframes slideExpandUp {
	0% {
		-webkit-transform: translateY(100%) scaleX(0.5);
	}
	30%{
		-webkit-transform: translateY(-8%) scaleX(0.5);
	}	
	40%{
		-webkit-transform: translateY(2%) scaleX(0.5);
	}
	50%{
		-webkit-transform: translateY(0%) scaleX(1.1);
	}
	60%{
		-webkit-transform: translateY(0%) scaleX(0.9);		
	}
	70% {
		-webkit-transform: translateY(0%) scaleX(1.05);
	}			
	80%{
		-webkit-transform: translateY(0%) scaleX(0.95);		
	}
	90% {
		-webkit-transform: translateY(0%) scaleX(1.02);
	}	
	100%{
		-webkit-transform: translateY(0%) scaleX(1);		
	}
}

/*
==============================================
expandUp
==============================================
*/


.expandUp{
	animation-name: expandUp;
	-webkit-animation-name: expandUp;	

	animation-duration: 0.7s;	
	-webkit-animation-duration: 0.7s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;		

	visibility: visible !important;	
}

@keyframes expandUp {
	0% {
		-webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);
		        transform: translateY(100%) scale(0.6) scaleY(0.5);
	}
	60%{
		-webkit-transform: translateY(-7%) scaleY(1.12);
		        transform: translateY(-7%) scaleY(1.12);
	}
	75%{
		-webkit-transform: translateY(3%);
		        transform: translateY(3%);
	}	
	100% {
		-webkit-transform: translateY(0%) scale(1) scaleY(1);
		        transform: translateY(0%) scale(1) scaleY(1);
	}	
}

@-webkit-keyframes expandUp {
	0% {
		-webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);
	}
	60%{
		-webkit-transform: translateY(-7%) scaleY(1.12);
	}
	75%{
		-webkit-transform: translateY(3%);
	}	
	100% {
		-webkit-transform: translateY(0%) scale(1) scaleY(1);
	}	
}

/*
==============================================
fadeIn
==============================================
*/

.fadeIn{
	animation-name: fadeIn;
	-webkit-animation-name: fadeIn;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes fadeIn {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
		opacity: 0.0;		
	}
	60% {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);	
	}
	80% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity: 1;	
	}		
}

@-webkit-keyframes fadeIn {
	0% {
		-webkit-transform: scale(0);
		opacity: 0.0;		
	}
	60% {
		-webkit-transform: scale(1.1);
	}
	80% {
		-webkit-transform: scale(0.9);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(1);
		opacity: 1;	
	}		
}

/*
==============================================
expandOpen
==============================================
*/


.expandOpen{
	animation-name: expandOpen;
	-webkit-animation-name: expandOpen;	

	animation-duration: 1.2s;	
	-webkit-animation-duration: 1.2s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	visibility: visible !important;	
}

@keyframes expandOpen {
	0% {
		-webkit-transform: scale(1.8);
		        transform: scale(1.8);		
	}
	50% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
	}	
	80% {
		-webkit-transform: scale(1.05);
		        transform: scale(1.05);
	}
	90% {
		-webkit-transform: scale(0.98);
		        transform: scale(0.98);
	}	
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}			
}

@-webkit-keyframes expandOpen {
	0% {
		-webkit-transform: scale(1.8);		
	}
	50% {
		-webkit-transform: scale(0.95);
	}	
	80% {
		-webkit-transform: scale(1.05);
	}
	90% {
		-webkit-transform: scale(0.98);
	}	
	100% {
		-webkit-transform: scale(1);
	}					
}

/*
==============================================
bigEntrance
==============================================
*/


.bigEntrance{
	animation-name: bigEntrance;
	-webkit-animation-name: bigEntrance;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	visibility: visible !important;			
}

@keyframes bigEntrance {
	0% {
		-webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		        transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		opacity: 0.2;
	}
	30% {
		-webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
		        transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);		
		opacity: 1;
	}
	45% {
		-webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		        transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	60% {
		-webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
		        transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	75% {
		-webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		        transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	90% {
		-webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
		        transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	100% {
		-webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		        transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1;
	}		
}

@-webkit-keyframes bigEntrance {
	0% {
		-webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		opacity: 0.2;
	}
	30% {
		-webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);		
		opacity: 1;
	}
	45% {
		-webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	60% {
		-webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	75% {
		-webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	90% {
		-webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	100% {
		-webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1;
	}				
}

/*
==============================================
hatch
==============================================
*/

.hatch{
	animation-name: hatch;
	-webkit-animation-name: hatch;	

	animation-duration: 2s;	
	-webkit-animation-duration: 2s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;

	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 

	visibility: visible !important;		
}

@keyframes hatch {
	0% {
		-webkit-transform: rotate(0deg) scaleY(0.6);
		        transform: rotate(0deg) scaleY(0.6);
	}
	20% {
		-webkit-transform: rotate(-2deg) scaleY(1.05);
		        transform: rotate(-2deg) scaleY(1.05);
	}
	35% {
		-webkit-transform: rotate(2deg) scaleY(1);
		        transform: rotate(2deg) scaleY(1);
	}
	50% {
		-webkit-transform: rotate(-2deg);
		        transform: rotate(-2deg);
	}	
	65% {
		-webkit-transform: rotate(1deg);
		        transform: rotate(1deg);
	}	
	80% {
		-webkit-transform: rotate(-1deg);
		        transform: rotate(-1deg);
	}		
	100% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}									
}

@-webkit-keyframes hatch {
	0% {
		-webkit-transform: rotate(0deg) scaleY(0.6);
	}
	20% {
		-webkit-transform: rotate(-2deg) scaleY(1.05);
	}
	35% {
		-webkit-transform: rotate(2deg) scaleY(1);
	}
	50% {
		-webkit-transform: rotate(-2deg);
	}	
	65% {
		-webkit-transform: rotate(1deg);
	}	
	80% {
		-webkit-transform: rotate(-1deg);
	}		
	100% {
		-webkit-transform: rotate(0deg);
	}		
}


/*
==============================================
bounce
==============================================
*/


.bounce{
	animation-name: bounce;
	-webkit-animation-name: bounce;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	
	
	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 	
}

@keyframes bounce {
	0% {
		-webkit-transform: translateY(0%) scaleY(0.6);
		        transform: translateY(0%) scaleY(0.6);
	}
	60%{
		-webkit-transform: translateY(-100%) scaleY(1.1);
		        transform: translateY(-100%) scaleY(1.1);
	}
	70%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05);
		        transform: translateY(0%) scaleY(0.95) scaleX(1.05);
	}
	80%{
		-webkit-transform: translateY(0%) scaleY(1.05) scaleX(1);
		        transform: translateY(0%) scaleY(1.05) scaleX(1);
	}	
	90%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1);
		        transform: translateY(0%) scaleY(0.95) scaleX(1);
	}				
	100%{
		-webkit-transform: translateY(0%) scaleY(1) scaleX(1);
		        transform: translateY(0%) scaleY(1) scaleX(1);
	}	
}

@-webkit-keyframes bounce {
	0% {
		-webkit-transform: translateY(0%) scaleY(0.6);
	}
	60%{
		-webkit-transform: translateY(-100%) scaleY(1.1);
	}
	70%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05);
	}
	80%{
		-webkit-transform: translateY(0%) scaleY(1.05) scaleX(1);
	}	
	90%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1);
	}				
	100%{
		-webkit-transform: translateY(0%) scaleY(1) scaleX(1);
	}		
}


/*
==============================================
pulse
==============================================
*/

.pulse{
	animation-name: pulse;
	-webkit-animation-name: pulse;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
		opacity: 0.7;		
	}
	50% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
		opacity: 0.7;	
	}			
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;		
	}
	50% {
		-webkit-transform: scale(1);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;	
	}			
}

/*
==============================================
floating
==============================================
*/

.floating{
	animation-name: floating;
	-webkit-animation-name: floating;

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes floating {
	0% {
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);	
	}
	50% {
		-webkit-transform: translateY(8%);
		        transform: translateY(8%);	
	}	
	100% {
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}			
}

@-webkit-keyframes floating {
	0% {
		-webkit-transform: translateY(0%);	
	}
	50% {
		-webkit-transform: translateY(8%);	
	}	
	100% {
		-webkit-transform: translateY(0%);
	}			
}

/*
==============================================
tossing
==============================================
*/

.tossing{
	animation-name: tossing;
	-webkit-animation-name: tossing;	

	animation-duration: 2.5s;	
	-webkit-animation-duration: 2.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes tossing {
	0% {
		-webkit-transform: rotate(-4deg);
		        transform: rotate(-4deg);	
	}
	50% {
		-webkit-transform: rotate(4deg);
		        transform: rotate(4deg);
	}
	100% {
		-webkit-transform: rotate(-4deg);
		        transform: rotate(-4deg);	
	}						
}

@-webkit-keyframes tossing {
	0% {
		-webkit-transform: rotate(-4deg);	
	}
	50% {
		-webkit-transform: rotate(4deg);
	}
	100% {
		-webkit-transform: rotate(-4deg);	
	}				
}

/*
==============================================
pullUp
==============================================
*/

.pullUp{
	animation-name: pullUp;
	-webkit-animation-name: pullUp;	

	animation-duration: 1.1s;	
	-webkit-animation-duration: 1.1s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 		
}

@keyframes pullUp {
	0% {
		-webkit-transform: scaleY(0.1);
		        transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
		        transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
		        transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
		        transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
		        transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
		        transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
		        transform: scaleY(1);
	}							
}

@-webkit-keyframes pullUp {
	0% {
		-webkit-transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
	}		
}

/*
==============================================
pullDown
==============================================
*/

.pullDown{
	animation-name: pullDown;
	-webkit-animation-name: pullDown;	

	animation-duration: 1.1s;	
	-webkit-animation-duration: 1.1s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 50% 0%;
	-ms-transform-origin: 50% 0%;
	-webkit-transform-origin: 50% 0%; 		
}

@keyframes pullDown {
	0% {
		-webkit-transform: scaleY(0.1);
		        transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
		        transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
		        transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
		        transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
		        transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
		        transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
		        transform: scaleY(1);
	}							
}

@-webkit-keyframes pullDown {
	0% {
		-webkit-transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
	}		
}

/*
==============================================
stretchLeft
==============================================
*/

.stretchLeft{
	animation-name: stretchLeft;
	-webkit-animation-name: stretchLeft;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 100% 0%;
	-ms-transform-origin: 100% 0%;
	-webkit-transform-origin: 100% 0%; 
}

@keyframes stretchLeft {
	0% {
		-webkit-transform: scaleX(0.3);
		        transform: scaleX(0.3);
	}
	40% {
		-webkit-transform: scaleX(1.02);
		        transform: scaleX(1.02);
	}
	60% {
		-webkit-transform: scaleX(0.98);
		        transform: scaleX(0.98);
	}
	80% {
		-webkit-transform: scaleX(1.01);
		        transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(0.98);
		        transform: scaleX(0.98);
	}				
	80% {
		-webkit-transform: scaleX(1.01);
		        transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(1);
		        transform: scaleX(1);
	}							
}

@-webkit-keyframes stretchLeft {
	0% {
		-webkit-transform: scaleX(0.3);
	}
	40% {
		-webkit-transform: scaleX(1.02);
	}
	60% {
		-webkit-transform: scaleX(0.98);
	}
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(0.98);
	}				
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(1);
	}		
}

/*
==============================================
stretchRight
==============================================
*/

.stretchRight{
	animation-name: stretchRight;
	-webkit-animation-name: stretchRight;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-webkit-transform-origin: 0% 0%; 		
}

@keyframes stretchRight {
	0% {
		-webkit-transform: scaleX(0.3);
		        transform: scaleX(0.3);
	}
	40% {
		-webkit-transform: scaleX(1.02);
		        transform: scaleX(1.02);
	}
	60% {
		-webkit-transform: scaleX(0.98);
		        transform: scaleX(0.98);
	}
	80% {
		-webkit-transform: scaleX(1.01);
		        transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(0.98);
		        transform: scaleX(0.98);
	}				
	80% {
		-webkit-transform: scaleX(1.01);
		        transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(1);
		        transform: scaleX(1);
	}							
}

@-webkit-keyframes stretchRight {
	0% {
		-webkit-transform: scaleX(0.3);
	}
	40% {
		-webkit-transform: scaleX(1.02);
	}
	60% {
		-webkit-transform: scaleX(0.98);
	}
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(0.98);
	}				
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(1);
	}		
}
